/**
 * Created by gschulz on 12.08.15.
 */
var EfbHelperTabIndicator = (function () {

    var _private = {};

    _private.instance = this;

    _private.container = $();

    _private.verbose = false;

    _private.events = ['ValidatorAddError', 'ValidatorRemoveError']; //DOMSubtreeModified

    _private.parentPanelLevel = 0;

    _private.excludeTabs = ['#tab-not-existing'];

    this.init = function (container) {
        _private.instance.setVerbose(_private.verbose);
        _private.instance.setParentPanelLevel(_private.parentPanelLevel);
        _private.container = container;
        _private.initObserveFrom();
    };

    this.checkPanelErrors = function (tabPanel) {
        var parentTabs    = tabPanel.parent('div.ui-tabs');
        var tabControl    = parentTabs.find('li[aria-controls=' + tabPanel.attr('id') + ']');
        var errorElements = tabPanel.find('div.formelement.error');
        if (_private.verbose && errorElements.length) {
            console.log('--------------------------------------');
            console.log(tabPanel.attr('id'));
            console.log(tabPanel);
            console.log(errorElements);
            console.log('--------------------------------------');
        }
        if (errorElements.length) {
            tabControl.addClass('incomplete');
        } else {
            tabControl.removeClass('incomplete');
        }
        _private.checkParentPanel(tabPanel, !!errorElements.length,_private.parentPanelLevel);
        _private.container.trigger('tabIndicatorCheckPanelErrors', [
            _private.container,
            _private.container.find('li.incomplete')
        ]);
    };

    _private.initObserveFrom = function () {
        var eventHelper = new EventHelper();
        _private.container.find('div.ui-tabs-panel').not(_private.excludeTabs.join(','))
                .unbind(_private.events.join('.checkError ') + '.checkError')
                .on(_private.events.join('.checkError ') + '.checkError', 'div.formelement',
                    function (event) {
                        var panel   = $(event.target).parents('div.ui-tabs-panel').first();
                        var formId  = $(event.target).parents('form').attr('id');
                        var panelId = _private.getParentPanelId(panel);
                        eventHelper.delayCall(
                            function () {
                                _private.instance.checkPanelErrors(panel);
                            },
                            'checkError-' + panelId + '-' + formId , [event], 100);
                    }
                );
    };

    _private.checkParentPanel = function (tabPanel, hasErrors, parentPanelLevel) {
        if (!parentPanelLevel) {
            return;
        }
        var parentPanel = tabPanel.parents('div.ui-tabs-panel').first();
        if (!parentPanel.length) {
            return;
        }
        var tabControl = parentPanel.parent('.ui-tabs').find('li[aria-controls=' + parentPanel.attr('id') + ']');
        if (hasErrors || parentPanel.find('li.incomplete[role=tab]').length) {
            tabControl.addClass('incomplete');
            hasErrors = true;
        } else {
            tabControl.removeClass('incomplete');
        }
        parentPanelLevel -= 1;
        _private.checkParentPanel(parentPanel, hasErrors,parentPanelLevel)
    };

    _private.getParentPanelId = function (parentPanel) {
        var panelId     = parentPanel.attr('id');
        if (_private.parentPanelLevel) {
            parentPanel = parentPanel.parents('div.ui-tabs-panel');
            if (parentPanel.length) {
                panelId += '-' + parentPanel.attr('id');
            }
        }
        return panelId;
    };

    /**
     *
     *  @param {Array} tabSelectors
     */
    this.setExcludeTabs = function (tabSelectors) {
        _private.excludeTabs = tabSelectors;
        return this;
    };

    this.setVerbose = function (verbose) {
        _private.verbose = !!verbose;
        return this;
    };

    this.setParentPanelLevel = function (level) {
        _private.parentPanelLevel = level;
        return this;
    };
});